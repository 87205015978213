<template>
  <div
    class="lg:w-1/2 md:w-3/4 w-full m-auto space-y-8"
    v-if="!isSubmissionComplete"
  >
    <!-- LINKED PRODUCTS -->
    <div class="borderedContainerClass p-4">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center justify-between">
          <vs-icon icon="flash_on" size="28px"></vs-icon>
          <p class="font-semibold lg:text-2xl md:text-xl text-lg">
            Linked Products
          </p>
        </div>
        <vs-button
          @click="handleOpenProductSidebar"
          icon="add"
          type="border"
          class="lg:flex md:flex hidden"
        >
          Add linked products
        </vs-button>
        <vs-button
          @click="handleOpenProductSidebar"
          type="border"
          class="lg:hidden md:hidden block"
        >
          Add
        </vs-button>
      </div>
      <p class="lg:text-base md:text-base text-sm">
        Linking the products associated to this aftercare template will send
        this aftercare document to your patient at the end of a treatment.
      </p>
      <div class="w-full">
        <!-- LOADER -->
        <div
          v-if="isFetchingTemplateDetails"
          class="productBoxClass w-full m-auto overflow-auto"
        >
          <div class="flex gap-3">
            <div
              class="aftercare-skeleton-loader aftercare-skeleton-loader-button w-1/2 h-12"
            ></div>
            <div
              class="aftercare-skeleton-loader aftercare-skeleton-loader-button w-1/3 h-12"
            ></div>
          </div>
        </div>
        <div v-else class="productBoxClass w-full m-auto overflow-auto">
          <div v-if="productTags.length">
            <div
              v-for="(item, index) in productTags"
              :key="index"
              class="overflow-hidden inline-block select-none"
            >
              <p
                class="productBoxClass py-2 px-4 rounded-lg ml-2 flex items-center"
              >
                {{ item.name }}
                <vs-icon
                  @click="handleRemoveProduct(item)"
                  icon="close"
                  size="12px"
                  class="ml-2 cursor-pointer"
                >
                </vs-icon>
              </p>
            </div>
          </div>
          <div
            v-else
            class="text-center lg:text-lg md:text-lg text-base select-none"
          >
            Add a linked product to get started
          </div>
        </div>
        <div v-if="productTags.length" class="mt-2">
          <vs-button
            type="flat"
            color="danger"
            icon="close"
            @click="handleClearSelectedProducts"
            >Clear Selected Products</vs-button
          >
        </div>
      </div>
    </div>
    <!-- AFTERCARE CONTENT -->
    <div class="borderedContainerClass px-4 py-2">
      <div class="flex items-center justify-between w-full">
        <p class="font-semibold lg:text-2xl md:text-xl text-lg">
          Aftercare content
        </p>
        <vs-button type="border" @click="handleShowAftercareSidebar">
          Edit
        </vs-button>
      </div>
      <vs-divider class="customDivider"></vs-divider>
      <!-- LOADER -->
      <div
        v-if="isFetchingTemplateDetails"
        class="opacity-50 aftercare-content-container space-y-2 w-full select-none overflow-hidden"
      >
        <div
          class="aftercare-skeleton-loader aftercare-skeleton-loader-button w-1/4"
        ></div>
        <div
          class="aftercare-skeleton-loader aftercare-skeleton-loader-button"
        ></div>
        <div
          class="aftercare-skeleton-loader aftercare-skeleton-loader-button w-2/3"
        ></div>
        <div
          class="aftercare-skeleton-loader aftercare-skeleton-loader-button w-4/5"
        ></div>
        <div
          class="aftercare-skeleton-loader aftercare-skeleton-loader-button"
        ></div>
      </div>
      <div
        v-else
        class="opacity-50 aftercare-content-container w-full select-none overflow-hidden"
        :class="
          aftercareInstructions
            ? 'opacity-100'
            : 'flex items-center justify-center'
        "
      >
        <div v-if="aftercareInstructions" v-html="aftercareInstructions"></div>
        <p v-else class="text-center italic text-lg h-full content-center">
          No Aftercare Instructions
        </p>
      </div>
    </div>
    <!-- LINKED PRODUCTS DROPDOWN -->
    <vs-sidebar
      class="sidebarx linkedProductSidebarClass relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="linkedProductsSidebar"
    >
      <!-- HEADER -->
      <div class="headerClass space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">Add Linked Products</p>
        <p class="px-4">
          Linked products will trigger this aftercare document to be sent to
          your patients email after you treatment is completed. Greyed out are
          already used.
        </p>
        <vs-divider></vs-divider>
        <vs-input
          ref="aftercareProducts"
          v-model="searchProduct"
          @input="customFilter"
          placeholder="Search products"
          class="w-full pb-4 px-4"
        />
      </div>
      <!-- BODY -->
      <div class="scrollableContent">
        <div v-for="item in products" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selectedProducts"
            :disabled="item.hasAftercareTemplate"
            :vs-value="item._id"
          >
            <span
              :class="{ 'opacity-50 select-none': item.hasAftercareTemplate }"
            >
              {{ item.name }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footerClass flex flex-col gap-3" slot="footer">
        <vs-button @click="handleSaveProducts" :disabled="isSavingProducts">
          <vs-icon
            v-if="isSavingProducts"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleExitProductSidebar">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
    <!-- AFTERCARE INSTRUCTIONS DROPDOWN -->
    <vs-sidebar
      class="sidebarx aftercareSidebar relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="showAftercareSidebar"
    >
      <!-- BODY -->
      <p class="lg:text-2xl md:text-xl text-lg font-semibold px-4 py-2">
        Aftercare Instructions
      </p>
      <vs-divider class="m-0"></vs-divider>
      <vue-ckeditor
        ref="aftercareInstructionsEditor"
        class="aftercareCKE"
        :config="config"
        v-model="content"
      />
      <!-- FOOTER -->
      <div class="footerClass flex flex-col gap-3" slot="footer">
        <vs-button @click="handleAftercareInstructions"> Save </vs-button>
        <vs-button type="border" @click.prevent="showAftercareSidebar = false">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
  <div
    v-else
    class="flex items-center justify-center aftercareCompleteFormClass"
  >
    <div
      class="border rounded-lg border-grey border-solid flex flex-col gap-12 items-center justify-center lg:p-24 md:p-16 p-12"
    >
      <vs-icon icon="check_box" class="text-primary" size="large"></vs-icon>
      <p class="lg:text-3xl text-xl text-center font-bold">
        Your aftercare document is complete.
      </p>
      <p class="lg:text-lg text-base text-center">
        Start using your aftercare doc during your treatments by
        <br class="lg:block md:block hidden" />
        either using a product linked to a document, or manually
        <br class="lg:block md:block hidden" />
        sending one from your treatment page.
      </p>
      <div class="flex flex-col items-center gap-3 w-full">
        <vs-button
          class="w-5/6 font-semibold lg:text-lg text-base mt-5"
          @click="handleRedirect"
          >Got it</vs-button
        >
        <p class="text-sm text-center">
          You will be automatically redirected to Aftercare Docs in
          {{ timeLeft }} second(s)...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueCkeditor from "vue-ckeditor2";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    VueCkeditor,
  },
  data() {
    return {
      config: {
        toolbar: [
          {
            name: "basicstyles",
            items: ["Bold", "Italic", "Strike"],
          },
          {
            name: "pagragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
              "Outdent",
              "Indent",
            ],
          },
        ],
        versionCheck: false,
      },
      linkedProductsSidebar: false,
      showAftercareSidebar: false,
      content: "",
      searchProduct: "",
      selectedProducts: [],
      productTags: [],
      aftercareInstructions: "",
      products: [],
      isSavingProducts: false,
      defaultProducts: [],
      productsInitialPull: true,
      isFetchingTemplateDetails: false,
      organizationId: null,
      timeLeft: 1,
      timerInterval: null,
    };
  },
  methods: {
    ...mapActions("aftercare", [
      "fetchAftercareProducts",
      "fetchTemplateDetails",
      "createAftercareTemplate",
      "updateAftercareTemplate",
      "previewAftercareTemplate",
    ]),
    handleRedirect() {
      this.$router.push({ name: "Aftercare" });
    },
    automaticRedirectTimer() {
      this.timeLeft = 10;
      this.timerInterval = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft === 1) {
          clearInterval(this.timerInterval);
          this.handleRedirect();
        }
      }, 1000);
    },
    resetTimer() {
      this.timeLeft = 1;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
    },
    handleOpenProductSidebar() {
      this.linkedProductsSidebar = true;
    },
    handleExitProductSidebar() {
      this.linkedProductsSidebar = false;
      if (this.searchProduct) {
        this.searchProduct = "";
        this.getAftercareProducts();
      }
    },
    customFilter: _.debounce(function (data) {
      this.searchProduct = data;
      this.getAftercareProducts();
    }, 500),
    handleAftercareInstructions() {
      if (this.content) {
        this.aftercareInstructions = this.content;
      } else {
        this.aftercareInstructions = "";
      }
      this.showAftercareSidebar = false;
    },
    async handleSaveProducts() {
      this.isSavingProducts = true;
      if (this.selectedProducts.length) {
        this.productTags = this.defaultProducts.filter(({ _id }) =>
          this.selectedProducts.includes(_id)
        );
      } else {
        this.productTags = [];
      }

      if (this.searchProduct) {
        this.searchProduct = "";
        this.getAftercareProducts();
      }

      this.linkedProductsSidebar = false;
      this.isSavingProducts = false;
    },
    handleRemoveProduct(item) {
      this.selectedProducts = this.selectedProducts.filter(
        (product) =>
          product !== this.products.find((p) => p.name === item.name)._id
      );

      this.productTags = this.productTags.filter(
        (product) => product._id !== item._id
      );
    },
    handleClearSelectedProducts() {
      this.selectedProducts = [];
      this.productTags = [];
    },
    handleShowAftercareSidebar() {
      this.showAftercareSidebar = true;
    },
    async getAftercareProducts() {
      try {
        const { id } = this.$route.params;
        const res = await this.fetchAftercareProducts({
          search: this.searchProduct,
          organizationId: this.organizationId,
          ...(id && { templateId: id }),
        });
        if (res.status == 200) {
          this.products = res.data.data;
          if (this.productsInitialPull) {
            this.defaultProducts = res.data.data;
          }
        }
        this.productsInitialPull = false;
      } catch (err) {
        console.error("ERR: ", err);
      }
    },
    async getAftercareTemplateDetails() {
      try {
        this.isFetchingTemplateDetails = true;
        const { id } = this.$route.params;
        const res = await this.fetchTemplateDetails({
          id,
        });
        if (res.status == 200) {
          const data = res.data.data;
          if (data.products && data.products.length) {
            this.selectedProducts = data.products.map(
              ({ productId }) => productId
            );
            this.handleSaveProducts();
          }
          if (data.details) {
            this.content = data.details;
            this.handleAftercareInstructions();
          }
          this.$store.dispatch("aftercare/updateAftercareDetails", {
            _id: data._id,
            name: data.name,
          });
        }
        this.isFetchingTemplateDetails = false;
      } catch (err) {
        this.isFetchingTemplateDetails = false;
        console.error("ERR: ", err);
      }
    },
    saveData() {
      if (this.isAddMode) return this.createAftercareTemplateProcess();
      this.updateAftercareTemplateProcess();
    },
    async createAftercareTemplateProcess() {
      try {
        if (this.isValid) {
          const payload = {
            name: this.aftercareDetails.name,
            products: this.selectedProducts,
            organizationId: this.organizationId,
            details: this.content,
          };
          const result = await this.createAftercareTemplate(payload);
          if (result.status === 201) {
            this.automaticRedirectTimer();
            this.$store.dispatch(
              "aftercare/updateAftercareFormCompletion",
              true
            );
          }
        } else {
          this.$vs.notify({
            title: "Aftercare content empty",
            text: "Please create an aftercare content",
            color: "danger",
          });
        }
        this.$store.dispatch("aftercare/updateFormSubmission", false);
      } catch (error) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to create aftercare template. Try again!",
          color: "danger",
        });
        console.error("Error create aftercare template: " + error);
      }
    },
    async updateAftercareTemplateProcess() {
      try {
        if (this.isValid) {
          const payload = {
            id: this.aftercareDetails._id,
            body: {
              name: this.aftercareDetails.name,
              products: this.selectedProducts,
              organizationId: this.organizationId,
              details: this.content,
            },
          };
          const res = await this.updateAftercareTemplate(payload);
          if (res.status == 200) {
            this.$store.dispatch(
              "aftercare/updateAftercareFormCompletion",
              true
            );
            this.automaticRedirectTimer();
          }
          this.$store.dispatch("aftercare/updateFormSubmission", false);
        } else {
          this.$vs.notify({
            title: "Aftercare content empty",
            text: "Please create an aftercare content",
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update aftercare template.",
          color: "danger",
        });
        console.error("Error update aftercare template: " + error);
      }
    },
    async handlePreview() {
      try {
        if (!this.content) {
          this.$vs.notify({
            title: "Aftercare content empty",
            text: "Please create an aftercare content",
            color: "danger",
          });
          this.$store.dispatch("aftercare/setPreviewLoading", false);
          return false;
        }
        const payload = [{
          name: this.aftercareDetails.name,
          details: this.content,
        }]
        const res = await this.previewAftercareTemplate({ template: payload });
        if (res.status === 200) {
          window.open(res.data.data, '_blank');
          this.$store.dispatch("aftercare/setPreviewLoading", false);
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch("aftercare/setPreviewLoading", false);
      }
    }
  },
  computed: {
    isAddMode() {
      return this.$route.name.search("Create") !== -1;
    },
    isSubmitting() {
      return this.$store.getters["aftercare/isFormSubmitting"];
    },
    aftercareDetails() {
      return this.$store.getters["aftercare/getAftercareDetails"];
    },
    isSubmissionComplete() {
      return this.$store.getters["aftercare/isSubmissionCompleted"];
    },
    isValid() {
      return this.content;
    },
    isPreviewLoading() {
      return this.$store.getters["aftercare/isPreviewLoading"];
    }
  },
  watch: {
    linkedProductsSidebar(newVal) {
      if (newVal) {
        // When sidebar is active, disable scroll on body
        document.body.style.overflow = "hidden";
      } else {
        // When sidebar is inactive, re-enable scroll
        document.body.style.overflow = "";
      }
    },
    showAftercareSidebar(newVal) {
      if (newVal) {
        // When sidebar is active, disable scroll on body
        document.body.style.overflow = "hidden";
      } else {
        // When sidebar is inactive, re-enable scroll
        document.body.style.overflow = "";
      }
    },
    isSubmitting: {
      handler(submitting) {
        if (submitting) {
          this.saveData();
        }
      },
    },
    isPreviewLoading: {
      handler(val) {
        if (val) {  
          this.handlePreview();
        }
      },
    },
  },
  async created() {
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    await this.getAftercareProducts();
    if (this.$route.name.search("Details") !== -1) {
      await this.getAftercareTemplateDetails();
    }
  },
  beforeDestroy() {
    this.resetTimer();
    this.$store.dispatch("aftercare/updateAftercareDetails", {});
    this.$store.dispatch("aftercare/updateFormSubmission", false);
    this.$store.dispatch("aftercare/updateAftercareFormCompletion", false);
    this.$refs.aftercareInstructionsEditor.destroy();
  },
};
</script>

<style>
.aftercareCompleteFormClass {
  height: 70dvh;
}
.aftercare-skeleton-loader {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.aftercare-skeleton-loader-button {
  width: 80%;
  height: 20px;
}

.vs-sidebar--background {
  z-index: 52000 !important;
}
.vs-sidebar.vs-sidebar-position-right {
  z-index: 53000;
}
.custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.custom-checkbox {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.custom-checkbox span {
  white-space: normal;
  word-break: break-word;
}
.aftercare-content-container {
  min-height: 16rem;
}
.aftercare-content-container ul {
  list-style-type: disc;
  padding-left: 20px;
}
.aftercare-content-container ol {
  list-style-type: decimal;
  padding-left: 20px;
}
.aftercareCKE .cke {
  border: none;
}
.aftercareCKE .cke_contents.cke_reset {
  height: calc(90dvh - 130px) !important;
}
.aftercareCKE .cke_top.cke_reset_all {
  background-color: white;
}
.aftercareCKE .cke_bottom.cke_reset_all {
  display: none;
}
.scrollableContent {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: 100%;
}
.headerClass {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.footerClass {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}
.borderedContainerClass {
  position: relative;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  border-radius: 0.5rem;
}
.productBoxClass {
  border: 1px solid #e0e0e0;
  padding: 15px 10px;
  border-radius: 0.5rem;
}
.productBoxClass p {
  color: #626262;
  background-color: #f0f0f0;
}
.customDivider {
  position: absolute;
  top: 35px;
}
.linkedProductSidebarClass .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}
.linkedProductSidebarClass .vs-sidebar--items {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.aftercareSidebar .vs-sidebar-primary {
  max-width: none;
}

@media (min-width: 768px) {
  .linkedProductSidebarClass .vs-sidebar-primary {
    max-width: 350px;
  }
  .aftercareSidebar .vs-sidebar-primary {
    max-width: 600px;
  }
}

@media (min-width: 1024px) {
  .linkedProductSidebarClass .vs-sidebar-primary {
    max-width: 350px;
  }
  .aftercareSidebar .vs-sidebar-primary {
    max-width: 600px;
  }
}
</style>